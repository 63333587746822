import { debounce } from "@solid-primitives/scheduled";
import { Component, createSignal, createEffect, on, Show } from "solid-js";
import { createStore, produce } from "solid-js/store";
// import { SelectBox } from "../inputs-v2";
import { SelectBox } from "../inputs";
import "./range.css";

export type RangeFields = {
  min: number;
  max: number;
  step: number;
  label?: string;
  class?: string;
  type?: "price";
  layout: "inputs" | "display";
  onChange: (values: {
    min: number | undefined;
    max: number | undefined;
  }) => void;
  currMinVal?: string | undefined;
  currMaxVal?: string | undefined;
};

export const fractionsList = [
  { value: 0, label: '0"' },
  { value: 0.125, label: '⅛"' },
  { value: 0.25, label: '¼"' },
  { value: 0.375, label: '⅜"' },
  { value: 0.5, label: '½"' },
  { value: 0.625, label: '⅝"' },
  { value: 0.75, label: '¾"' },
  { value: 0.875, label: '⅞"' },
];

const quartersList = [
  { value: 0, label: ".00" },
  { value: 0.25, label: ".25" },
  { value: 0.5, label: ".50" },
  { value: 0.75, label: ".75" },
];

export const RangeSelector: Component<RangeFields> = (props) => {
  let minValRef: HTMLInputElement;
  let maxValRef: HTMLInputElement;
  let range: HTMLDivElement;
  const [rangeWidth, setRangeWidth] = createSignal();
  const [rangeLeft, setRangeLeft] = createSignal();
  const [values, setValues] = createStore({
    min: {
      completeVal: props.min,
      get deconWholeVal() {
        return Math.trunc(this.completeVal);
      },
      get deconDecVal() {
        return this.completeVal % 1;
      },
      get minVal() {
        return this.completeVal;
      },

      wholeValFromInput: 0,
      decValFromInput: 0,
      get constructedValFromInput() {
        return this.wholeValFromInput + this.decValFromInput;
      },
    },
    max: {
      completeVal: props.max,
      get deconWholeVal() {
        return Math.trunc(this.completeVal);
      },
      get deconDecVal() {
        return this.completeVal % 1;
      },
      get maxVal() {
        return this.completeVal;
      },
      wholeValFromInput: Math.trunc(props.max),
      decValFromInput: props.max % 1,
      get constructedValFromInput() {
        return this.wholeValFromInput + this.decValFromInput;
      },
    },
  });

  const dynamicOptions = Array.from(Array(Math.trunc(props.max) + 1).keys());

  const getPercent = (value: number) => {
    return Math.round(((value - props.min) / (props.max - props.min)) * 100);
  };
  createEffect(() => {
    if (maxValRef) {
      const minPercent = getPercent(values.min.completeVal);
      const maxPercent = getPercent(+maxValRef.value);
      if (range) {
        setRangeLeft(minPercent);
        setRangeWidth(maxPercent - minPercent);
      }
    }
  });
  createEffect(() => {
    if (minValRef) {
      const minPercent = getPercent(+minValRef.value);
      const maxPercent = getPercent(values.max.completeVal);
      if (range) {
        setRangeWidth(maxPercent - minPercent);
      }
    }
  });

  createEffect(
    on(
      () => [props.currMinVal, props.currMaxVal],
      () => {
        if (props.currMaxVal && props.currMinVal) {
          setValues("min", "completeVal", +props.currMinVal);
          setValues("max", "completeVal", +props.currMaxVal);
        } else {
          setValues("min", "completeVal", +props.min);
          setValues("max", "completeVal", +props.max);
        }
      }
    )
  );

  const updateProps = debounce(
    (vals: { min: number | undefined; max: number | undefined }) =>
      props.onChange(vals),
    500
  );

  createEffect(
    on(
      () => [values.min.minVal, values.max.maxVal],
      ([min, max]) => {
        if (min == props.min && max == props.max) {
          updateProps({ min: undefined, max: undefined });
        } else {
          updateProps({ min: min, max: max });
        }
      },
      { defer: true }
    )
  );

  return (
    <div
      class="w-full pr-1 pl-1 flex flex-col"
      classList={{ [`${props.class}`]: !!props.class }}
    >
      <Show when={props.label}>
        <h3 class="text-sm font-medium">{props.label}</h3>
      </Show>
      <div class="grid mt-6">
        <input
          aria-label={`Min ${props.label}`}
          type="range"
          ref={(ref) => (minValRef = ref)}
          value={values.min.completeVal}
          min={props.min}
          max={props.max}
          step={props.step}
          class="thumb thumb--zindex-3 col-start-1 row-start-1"
          onMouseUp={() => {
            props.onChange({ min: values.min.minVal, max: values.max.maxVal });
          }}
          onInput={(event) => {
            const value = +event.currentTarget.value;
            setValues("min", "completeVal", value);
          }}
        />
        <input
          aria-label={`Max ${props.label}`}
          type="range"
          ref={(ref) => (maxValRef = ref)}
          value={values.max.completeVal}
          min={props.min}
          max={props.max}
          step={props.step}
          class="thumb thumb--zindex-4 col-start-1 row-start-1"
          onMouseUp={() => {
            props.onChange({ min: values.min.minVal, max: values.max.maxVal });
          }}
          onInput={(event) => {
            const value = +event.currentTarget.value;
            setValues("max", "completeVal", value);
          }}
        />
        <div class="slider col-start-1 row-start-1">
          <div class="slider__track" />
          <div
            ref={(ref) => (range = ref)}
            class="slider__range"
            style={{
              width: rangeWidth() ? `${rangeWidth()}%` : undefined,
              left: rangeLeft() ? `${rangeLeft()}%` : undefined,
            }}
          />
        </div>
      </div>
      <div class=" mt-6 grid grid-cols-[1fr_max-content_1fr] gap-1 items-center text-xs">
        <div class="grid grid-cols-2">
          <Show when={props.layout === "inputs"}>
            <SelectBox
              triggerClass="rounded-sm rounded-r-none border-r-0 !px-2 h-9"
              contentClass="min-w-[100px]"
              chevronClass="w-3 h-3"
              defaultValue={values.min.deconWholeVal.toString()}
              value={{ value: values.min.deconWholeVal.toString() }}
              options={dynamicOptions.map((item) => {
                return {
                  label: `${
                    props.type == "price"
                      ? "$" + item.toString()
                      : item.toString() + '"'
                  }`,
                  value: +item,
                };
              })}
              onChange={(option) => {
                const value = Number(option.value);
                setValues("min", "wholeValFromInput", value);
                setValues(
                  "min",
                  "completeVal",
                  values.min.constructedValFromInput
                );
              }}
            />
            <SelectBox
              triggerClass="rounded-sm rounded-l-none border-l !px-2 h-9"
              contentClass="min-w-[100px]"
              chevronClass="w-3 h-3"
              defaultValue={values.min.deconDecVal.toString()}
              value={{ value: values.min.deconDecVal.toString() }}
              options={props.type === "price" ? quartersList : fractionsList}
              onChange={(option) => {
                const value = Number(option.value);
                setValues("min", "decValFromInput", value);
                setValues(
                  "min",
                  "completeVal",
                  values.min.constructedValFromInput
                );
              }}
            />
          </Show>
          <Show when={props.layout === "display"}>
            <p class="col-span-2 text-sm text-roma-dark-grey border text-center py-2">
              ${values.min.minVal.toFixed(2)}
            </p>
          </Show>
        </div>
        <p>-</p>
        <div class="grid grid-cols-2">
          <Show when={props.layout === "inputs"}>
            <SelectBox
              triggerClass="rounded-sm rounded-r-none border-r-0 !px-2 h-9"
              contentClass="min-w-[100px]"
              chevronClass="w-3 h-3"
              defaultValue={values.max.deconWholeVal.toString()}
              value={{ value: values.max.deconWholeVal.toString() }}
              options={dynamicOptions.map((item) => {
                return {
                  label: `${
                    props.type == "price"
                      ? "$" + item.toString()
                      : item.toString() + '"'
                  }`,
                  value: +item,
                };
              })}
              onChange={(option) => {
                const value = Number(option.value);
                setValues("max", "wholeValFromInput", value);
                setValues(
                  "max",
                  "completeVal",
                  values.max.constructedValFromInput
                );
              }}
            />
            <SelectBox
              triggerClass="rounded-sm rounded-l-none border-l !px-2 h-9"
              contentClass="min-w-[100px]"
              chevronClass="w-3 h-3"
              defaultValue={values.max.deconDecVal.toString()}
              value={{ value: values.max.deconDecVal.toString() }}
              options={props.type === "price" ? quartersList : fractionsList}
              onChange={(option) => {
                const value = Number(option.value);
                setValues("max", "decValFromInput", value);
                setValues(
                  "max",
                  "completeVal",
                  values.max.constructedValFromInput
                );
              }}
            />
          </Show>
          <Show when={props.layout === "display"}>
            <p class="col-span-2  border py-2 text-sm text-roma-dark-grey text-center">
              ${values.max.maxVal.toFixed(2)}
            </p>
          </Show>
        </div>
        <div
          class="col-span-full flex justify-between"
          classList={{ "mt-2": props.layout === "inputs" }}
        >
          <p class="text-roma-dark-grey text-xs">Min {props.label}</p>
          <p class="text-roma-dark-grey text-xs text-right">
            Max {props.label}
          </p>
        </div>
      </div>
      <Show
        when={
          values.min.completeVal !== props.min ||
          values.max.completeVal !== props.max
        }
      >
        <button
          class="text-roma-blue self-end text-xs mt-2"
          onClick={() => {
            setValues(
              produce((store) => {
                store.min.completeVal = props.min;
                store.max.completeVal = props.max;
              })
            );
          }}
        >
          Clear
        </button>
      </Show>
    </div>
  );
};

import { Component, createSignal, Show } from "solid-js";
import { Motion, Presence } from "solid-motionone";
import { isServer } from "solid-js/web";
import { createStore } from "solid-js/store";
import { A } from "@solidjs/router";
import { xMark } from "solid-heroicons/solid";
import { Icon } from "solid-heroicons";
import { fractionsList } from "~/components/shop";
import fractionString from "~/utils/fraction";
import { SelectBox } from "~/components/inputs";
import { Img } from "~/components/image";

type FrameCalcProps = {
  data: {
    sku: string;
    collection: string;
    colour: string;
    thumb: string;
    width: number;
  };
};

export const FrameCalculator: Component<FrameCalcProps> = (props) => {
  const [inside, setInside] = createSignal(true);
  const [error, setError] = createSignal(false);

  let defaultWidth = 0;
  let defaultHeight = 0;
  if (!isServer) {
    if (localStorage.getItem("chosenWidth"))
      defaultWidth = Number(localStorage.getItem("chosenWidth"));
    if (localStorage.getItem("chosenHeight"))
      defaultHeight = Number(localStorage.getItem("chosenHeight"));
  }

  const [values, setValues] = createStore({
    width: {
      completeVal: Number(defaultWidth),
      get deconWholeVal() {
        return Math.trunc(this.completeVal);
      },
      get deconDecVal() {
        return this.completeVal % 1;
      },
      // get widthVal() {
      //   return this.defaultWidth;
      // },
      wholeValFromInput: Math.trunc(defaultWidth),
      decValFromInput: defaultWidth % 1,
      get constructedValFromInput() {
        return this.wholeValFromInput + this.decValFromInput;
      },
    },
    height: {
      completeVal: Number(defaultHeight),
      get deconWholeVal() {
        return Math.trunc(this.completeVal);
      },
      get deconDecVal() {
        return this.completeVal % 1;
      },
      // get heightVal() {
      //   return this.defaultWidth;
      // },
      wholeValFromInput: Math.trunc(defaultHeight),
      decValFromInput: defaultHeight % 1,
      get constructedValFromInput() {
        return this.wholeValFromInput + this.decValFromInput;
      },
    },

    frameModelWidth: props.data.width,
    /**
     * @returns [width, height] )
     */
    get insideEdge() {
      if (inside()) {
        setError(false);
        // return `${this.width.completeVal}" x ${this.height.completeVal}"`;
        return [this.width.completeVal, this.height.completeVal];
      }
      if (
        this.width.completeVal - this.frameModelWidth * 2 < 0 ||
        this.height.completeVal - this.frameModelWidth * 2 < 0
      ) {
        setError(true);
        // return "-";
        return ["-", "-"];
      }
      setError(false);
      // return `${this.width.completeVal - this.frameModelWidth * 2}" x ${
      //   this.height.completeVal - this.frameModelWidth * 2
      // }"`;
      return [
        this.width.completeVal - this.frameModelWidth * 2,
        this.height.completeVal - this.frameModelWidth * 2,
      ];
    },
    /**
     * @returns [width, height] )
     */
    get outsideEdge() {
      if (inside()) {
        // return `${this.width.completeVal + this.frameModelWidth * 2}" x ${
        //   this.height.completeVal + this.frameModelWidth * 2
        // }"`;
        return [
          this.width.completeVal + this.frameModelWidth * 2,
          this.height.completeVal + this.frameModelWidth * 2,
        ];
      }
      // return `${this.width.completeVal}" x ${this.height.completeVal}"`;
      return [this.width.completeVal, this.height.completeVal];
    },
    get mouldingNeeded() {
      if (inside()) {
        // return `${
        //   (this.width.completeVal + this.frameModelWidth * 2) * 2 +
        //   (this.height.completeVal + this.frameModelWidth * 2) * 2
        // }"`;
        return (
          (this.width.completeVal + this.frameModelWidth * 2) * 2 +
          (this.height.completeVal + this.frameModelWidth * 2) * 2
        );
      }
      // return `${this.width.completeVal * 2 + this.height.completeVal * 2}"`;
      return this.width.completeVal * 2 + this.height.completeVal * 2;
    },
    /**
     * @returns [length in ft, remainder in inches] )
     */
    get mouldingFeetNeeded() {
      const insideNeeded =
        (this.width.completeVal + this.frameModelWidth * 2) * 2 +
        (this.height.completeVal + this.frameModelWidth * 2) * 2;
      const outsideNeeded =
        this.width.completeVal * 2 + this.height.completeVal * 2;

      if (inside()) {
        // return `${Math.trunc(insideNeeded / 12)}' ${(insideNeeded % 12).toFixed(3)}" `;
        return [insideNeeded / 12, insideNeeded % 12];
      }
      // return `${Math.trunc(outsideNeeded / 12)}' ${(outsideNeeded % 12).toFixed(3)}" `;
      return [outsideNeeded / 12, outsideNeeded % 12];
    },
  });

  const wholeNumList = Array.from(Array(119)).map((_, index) => {
    return { label: index.toString() + '"', value: index };
  });

  return (
    <div class="flex flex-col gap-4">
      <div>
        <h3 class="text-xl font-bold">Frame Calculator</h3>
        <div class="flex gap-2">
          <h3>{props.data.sku}</h3>
          <h3>{props.data.collection}</h3>
          <span>&mdash;</span>
          <h3>{props.data.colour}</h3>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <h4 class="text-roma-dark-grey text-sm font-bold">Dimensions</h4>
        <div class="grid grid-cols-2 border">
          <button
            aria-label="Inside measurements"
            class="p-3 font-bold text-sm transition-colors duration-500"
            classList={{ "bg-black text-white": inside() }}
            onClick={() => {
              setInside(true);
            }}
          >
            Inside
          </button>
          <button
            aria-label="Outside measurements"
            class="p-3 font-bold text-sm transition-colors duration-500"
            classList={{ "bg-black text-white": !inside() }}
            onClick={() => {
              setInside(false);
            }}
          >
            Outside
          </button>
        </div>
      </div>
      <div class="grid grid-cols-[1fr_max-content_1fr] gap-4">
        <div class="flex flex-col gap-2">
          <h4 class="text-sm font-bold text-roma-dark-grey">Width</h4>
          <div class="grid grid-cols-2">
            <SelectBox
              triggerClass="rounded-sm border-r-0 rounded-r-none"
              options={wholeNumList}
              defaultValue={values.width.deconWholeVal}
              onChange={(option) => {
                const value = Number(option.value);
                setValues("width", "wholeValFromInput", value);
                setValues(
                  "width",
                  "completeVal",
                  values.width.constructedValFromInput
                );
                localStorage.setItem(
                  "chosenWidth",
                  values.width.constructedValFromInput.toString()
                );
              }}
            />
            <SelectBox
              triggerClass="rounded-sm rounded-l-none"
              options={fractionsList}
              defaultValue={values.width.deconDecVal}
              onChange={(option) => {
                const value = Number(option.value);
                setValues("width", "decValFromInput", value);
                setValues(
                  "width",
                  "completeVal",
                  values.width.constructedValFromInput
                );
                localStorage.setItem(
                  "chosenWidth",
                  values.width.constructedValFromInput.toString()
                );
              }}
            />
          </div>
        </div>
        <div>
          <Icon path={xMark} class="w-5 relative top-9 text-gray-400" />
        </div>
        <div class="flex flex-col gap-2">
          <h4 class="text-sm font-bold text-roma-dark-grey">Height</h4>
          <div class="grid grid-cols-2">
            <SelectBox
              triggerClass="rounded-sm border-r-0 rounded-r-none"
              options={wholeNumList}
              defaultValue={values.height.deconWholeVal}
              onChange={(option) => {
                const value = Number(option.value);
                setValues("height", "wholeValFromInput", value);
                setValues(
                  "height",
                  "completeVal",
                  values.height.constructedValFromInput
                );
                localStorage.setItem(
                  "chosenHeight",
                  values.height.constructedValFromInput.toString()
                );
              }}
            />
            <SelectBox
              triggerClass="rounded-sm rounded-l-none"
              options={fractionsList}
              defaultValue={values.height.deconDecVal}
              onChange={(option) => {
                const value = Number(option.value);
                setValues("height", "decValFromInput", value);
                setValues(
                  "height",
                  "completeVal",
                  values.height.constructedValFromInput
                );
                localStorage.setItem(
                  "chosenHeight",
                  values.height.constructedValFromInput.toString()
                );
              }}
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex justify-between">
          <h4 class="text-sm font-bold text-roma-dark-grey">
            Calculated Dimensions
          </h4>
          <Presence exitBeforeEnter>
            <Show when={error()}>
              <Motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10, transition: { duration: 0.175 } }}
                transition={{ duration: 0.2, easing: "ease-in-out" }}
                class="text-xs text-red-500 text-right"
              >
                Frame size not possible, please adjust size
              </Motion.div>
            </Show>
          </Presence>
        </div>
        <div class="border grid sm:grid-cols-3 p-3 sm:items-start">
          <div class="flex gap-2 items-center">
            <div class="w-10 h-10 p-1 flex items-center bg-roma-grey">
              <Img
                src={props.data.thumb}
                width={32}
                height={32}
                layout="constrained"
                alt=""
              />
            </div>
            <p class="text-sm">{props.data.sku}</p>
          </div>
          <div class="flex flex-col max-sm:border-t max-sm:pt-2 max-sm:mt-2 text-sm col-span-2">
            <div class="flex justify-between">
              <h4>Moulding Width</h4>
              <p class="fraction" title={values.frameModelWidth.toString()}>
                {fractionString(values.frameModelWidth)}
              </p>
            </div>
            <div class="flex justify-between">
              <h4>Inside Edge</h4>
              <p class="fraction">
                { fractionString(values.insideEdge[0] as number)} x{" "}
                { fractionString(values.insideEdge[1] as number)}
              </p>
            </div>
            <div class="flex justify-between">
              <h4>Outside Edge</h4>
              <p class="fraction">
                {fractionString(values.outsideEdge[0])} x{" "}
                {fractionString(values.outsideEdge[1])}
              </p>
            </div>
            <div class="flex justify-between">
              <h4>Moulding Needed</h4>
              <p class="fraction">
                {" "}
                {Math.trunc(values.mouldingFeetNeeded[0])}'{" "}
                {fractionString(values.mouldingFeetNeeded[1])}
              </p>
            </div>
          </div>
        </div>
        <p class="text-xs text-roma-dark-grey">
          *No waste factor considered. Sticks length ignored. For further
          information please{" "}
          <A href="/support" class="text-roma-blue">
            contact us
          </A>
        </p>
      </div>
    </div>
  );
};

import {
  Component,
  Show,
  For,
  Switch,
  Match,
  createMemo,
  createEffect,
  InitializedResource,
  ErrorBoundary,
} from "solid-js";
import { BaseLoader } from "~/components/utility";
import { BOX, LENGTH, JOIN, CHOP, CONTRACT } from "~/utils/products";
import { isNullUndefinedOrEmpty } from "~/utils/helpers";
import { Motion } from "solid-motionone";
import {
  A,
  AccessorWithLatest,
  useSearchParams,
  createAsync,
  useParams,
} from "@solidjs/router";
import type { Product } from "~/services/roma-api/products/types";
import type { FormattedPricing } from "~/routes/(layout)/product/[sku]";
import { getProductPricing } from "~/services/products";
import { ThresholdStatus, stockThreshold } from "~/utils/threshold";
import { useErrorContext } from "~/utils/contexts";
import { checkError } from "~/services/roma-api/checkError";

type PricingBoxProps = {
  product: AccessorWithLatest<Product | undefined>;
};

export const PricingBox: Component<PricingBoxProps> = (props) => {
  // TODO: Investigate occasional error of "cannot use 'in' operator..."
  const routeParams = useParams();
  const [params, setParams] = useSearchParams();
  const { addError } = useErrorContext();
  // ! TESTING

  const pricing = createAsync(async () => {
    try {
      const data: FormattedPricing = await getProductPricing(routeParams.sku);

      // TODO
      // ! Don't actually need the formatted structure here..only for inventory box. Remove

      data.availableAs = Object.keys(data.Pricing) as string[];

      data.defaultPlantLowStock = [
        ThresholdStatus.lowStock,
        ThresholdStatus.zeroStock,
      ].includes(
        stockThreshold(
          data.Inventory[data.Plant],
          props.product()?.Category!,
          props.product()?.Discontinued
        ).status
      );

      const checkAllPlants = () => {
        if (data.defaultPlantLowStock) return true;

        for (const item of Object.entries(data.Inventory)) {
          const status: ThresholdStatus = stockThreshold(
            item[1] as number,
            props.product()?.Category as string,
            props.product()?.Discontinued
          ).status;

          if (
            [ThresholdStatus.lowStock, ThresholdStatus.zeroStock].includes(
              status
            )
          ) {
            return true;
          }
        }
        return false;
      };
      data.anyPlantLowStock = checkAllPlants();

      return data;
    } catch (error) {
      console.log(error);
      const err = checkError(error);
      addError(err, {
        severity: "critical",
        title: "Product Data Error",
        message:
          "An error occurred while retrieving product details - pricing, inventory, etc. Please try again, or contact support if this error persists.",
        showDetails: true,
      });
      throw err;
      // If we *dont* throw the error, the addError notif loads on both navigation and direct loading of the page..but if we don't through, the EB is not hit..
      // Might need to throw here, and fire the addError from the error boundary ? decide later, still visible and functional.
    }
  });

  // createEffect(()=>console.log("PRICING >>>> ", pricing()))

  const defaultType = () => {
    if (
      pricing()?.Currency === "CAD" &&
      props.product()?.Category === "Roma Elite"
    ) {
      return JOIN;
    }
    return LENGTH;
  };

  const productType = () => {
    if (!params.type) return defaultType();
    return params.type;
  };

  // ! END

  let underline: HTMLDivElement;
  const allowedTypesByLocation: Record<string, string[]> = {
    USA: [LENGTH, CHOP, BOX, CONTRACT],
    CAN: [LENGTH, JOIN, BOX, CONTRACT],
  };

  const typeDict: Record<string, string> = {
    [LENGTH]: "Length",
    [CHOP]: "Chop",
    [JOIN]: "Join",
    [BOX]: "Box",
    [CONTRACT]: "Contract",
  };

  const availableTypes = createMemo(() => {
    if (!props.product()?.AvailableAs || !pricing()?.Currency) return [];
    let country;
    if (pricing()?.Currency === "USD") {
      country = "USA";
    } else if (pricing()?.Currency === "CAD") {
      country = "CAN";
    } else return [];

    const list = allowedTypesByLocation[country as "USA" | "CAN"].filter(
      (type: string) => props.product()?.AvailableAs.includes(type)
    );
    return list;
  });

  const handleClick = (e: MouseEvent) => {
    if (underline && (e.target as Element).tagName == "BUTTON") {
      const target = e.target as HTMLButtonElement;
      underline.style.left = target.offsetLeft + "px";
      underline.style.width = target.offsetWidth + "px";
    }
  };

  createEffect(() => {
    if (productType()) {
      moveUnderline();
    }
  });

  const moveUnderline = () => {
    if (productType() && underline) {
      const buttons = document.querySelectorAll("button[data-type]");
      const active = [...buttons].find(
        (button) => button.getAttribute("data-type") === productType()
      ) as HTMLButtonElement;
      if (active) {
        underline.style.left = active.offsetLeft + "px";
        underline.style.width = active.offsetWidth + "px";
      }
    }
  };

  const PriceLayout: Component<{
    amount: number;
    discount?: number;
    overrideDiscount?: number;
  }> = (props) => {
    const discount = () => Math.abs(props.discount ?? 0);
    return (
      <Motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Show
          when={!isNullUndefinedOrEmpty(props.amount) && !isNaN(props.amount)}
          fallback={
            <span class="text-sm text-roma-dark-grey font-light">
              Error fetching pricing info, please{" "}
              <a href="tel:18002632322" rel="nofollow" class="text-roma-blue">
                call us
              </a>{" "}
              for further information on this product.
            </span>
          }
        >
          <span class="text-xl font-bold">${props.amount.toFixed(2)}/ft</span>
          <Show when={discount() > 0 || props.overrideDiscount}>
            <span class="ml-3 text-base  text-roma-medium-grey line-through">
              $
              {props.overrideDiscount
                ? props.overrideDiscount.toFixed(2)
                : (discount() + props.amount).toFixed(2)}
            </span>
            {/* DISCOUNT PERCENTAGE - to be replaced by percentage value from SAP */}
            {/* <span class="ml-3 text-red-500 font-bold text-base">
              {Math.floor((discount() / (props.amount + discount())) * 100)}%
              off
            </span> */}
          </Show>
        </Show>
      </Motion.div>
    );
  };

  return (
    <>
      <ErrorBoundary
        fallback={(error, reset) => {
          // TODO - Sentry
          return (
            <div class="bg-red-100 rounded-md p-3 text-sm">
              <p>
                Error retrieving pricing information. Please try again later, we
                apologize for the inconvenience.
              </p>
            </div>
          );
        }}
      >
        <Show when={pricing()}>
          <div class=" w-full pt-2">
            <div
              class="box-border flex md:justify-end relative"
              onClick={handleClick}
              classList={{ "border-b": availableTypes().length > 0 }}
            >
              <For each={availableTypes()}>
                {(type: string) => (
                  <button
                    data-type={type}
                    class="text-sm text-roma-medium-grey transition-[color] px-2 py-2 "
                    onClick={() => {
                      setParams({ type: type }, { replace: true });
                    }}
                    classList={{
                      "!text-roma-blue hover:text-roma-blue":
                        productType() === type,
                      "hover:text-black": productType() !== type,
                    }}
                  >
                    {typeDict[type]}
                  </button>
                )}
              </For>
              <div
                ref={(ref) => (underline = ref)}
                class="absolute h-[1px] left-0 -bottom-px bg-black  transition-all duration-500"
              />
            </div>
            <div class="pt-6">
              <Show
                when={pricing()?.Pricing}
                fallback={
                  // TODO : Handle errors!
                  <div class="flex border-t pt-4">
                    <BaseLoader width={5} height={5} />
                    <p class="ml-2">Retrieving pricing information</p>
                  </div>
                  // <Switch
                  //   fallback={
                  //     <div class="flex border-t pt-4">
                  //       <BaseLoader width={5} height={5} />
                  //       <p class="ml-2">Retrieving pricing information</p>
                  //     </div>
                  //   }
                  // >
                  //   <Match
                  //     when={
                  //       props.data.latest.Error?.Code === "SERVICE_UNAVAILABLE"
                  //     }
                  //   >
                  //     <div class="border rounded-md p-3 text-sm bg-orange-50">
                  //       There was an error while retrieving pricing. Please try
                  //       again later or kindly contact{" "}
                  //       <A href="./support" class="text-roma-blue">
                  //         customer service
                  //       </A>{" "}
                  //       for additional support.
                  //     </div>
                  //   </Match>
                  //   <Match
                  //     when={props.data.latest.Error?.Code === "CREDIT_BLOCK"}
                  //   >
                  //     <div class="border rounded-md p-3 text-sm bg-orange-50">
                  //       Unfortunately we are unable to display prices for this
                  //       product because your Roma account has been blocked. Kindly
                  //       contact{" "}
                  //       <A href="./support" class="text-roma-blue">
                  //         customer service
                  //       </A>{" "}
                  //       for additional support.
                  //     </div>
                  //   </Match>
                  // </Switch>
                }
              >
                <Switch>
                  <Match
                    when={
                      productType() === LENGTH &&
                      props.product()?.Discontinued &&
                      pricing()?.Pricing?.[LENGTH]
                    }
                  >
                    <PriceLayout
                      amount={
                        pricing()?.Pricing[LENGTH]?.Amount! +
                        Math.abs(pricing()?.Pricing[LENGTH]?.Discount ?? 0) -
                        (pricing()?.Pricing[LENGTH]?.Amount! +
                          Math.abs(pricing()?.Pricing[LENGTH]?.Discount ?? 0)) *
                          ((props.product()?.DiscontinuedDiscount || 80) / 100)
                      }
                      overrideDiscount={
                        pricing()?.Pricing[LENGTH]?.Amount! +
                        Math.abs(pricing()?.Pricing[LENGTH]?.Discount ?? 0)
                      }
                    />
                  </Match>
                  <Match
                    when={
                      productType() === LENGTH && pricing()?.Pricing?.[LENGTH]
                    }
                  >
                    <PriceLayout
                      amount={pricing()?.Pricing[LENGTH]?.Amount!}
                      discount={pricing()?.Pricing[LENGTH]?.Discount}
                    />
                  </Match>
                  <Match
                    when={productType() === CHOP && pricing()?.Pricing?.[CHOP]}
                  >
                    <PriceLayout
                      amount={pricing()?.Pricing[CHOP]?.Amount!}
                      discount={pricing()?.Pricing[CHOP]?.Discount}
                    />
                  </Match>
                  <Match
                    when={productType() === BOX && pricing()?.Pricing?.[BOX]}
                  >
                    <PriceLayout
                      amount={
                        pricing()?.Pricing[BOX]?.Amount! /
                        pricing()?.Pricing[BOX]?.Quantity!
                      }
                      discount={
                        pricing()?.Pricing[LENGTH]?.Amount! +
                        Math.abs(pricing()?.Pricing[LENGTH]?.Discount!) -
                        pricing()?.Pricing[BOX]?.Amount! /
                          pricing()?.Pricing[BOX]?.Quantity!
                      }
                    />
                  </Match>
                  <Match
                    when={
                      productType() === CONTRACT &&
                      pricing()?.Pricing?.[CONTRACT] &&
                      pricing()?.Pricing?.[LENGTH]
                    }
                  >
                    <PriceLayout
                      amount={pricing()?.Pricing[CONTRACT]?.Amount!}
                      discount={
                        pricing()?.Pricing[LENGTH]?.Amount! +
                        Math.abs(pricing()?.Pricing[LENGTH]?.Discount!) -
                        pricing()?.Pricing[CONTRACT]?.Amount!
                      }
                    />
                  </Match>
                  <Match
                    when={
                      productType() === JOIN &&
                      pricing()?.Currency === "CAD" &&
                      pricing()?.Pricing?.[JOIN]
                    }
                  >
                    <span class="text-lg font-bold">
                      Join Code: {pricing()?.Pricing[JOIN]?.Code}
                    </span>
                  </Match>
                </Switch>
              </Show>
            </div>
          </div>
        </Show>
      </ErrorBoundary>
    </>
  );
};

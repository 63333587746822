import { Component, For, JSX } from "solid-js";

export type GridSelectorOption = {
  width: number | string;
  height: number | string;
  label: string | JSX.Element;
  value: string | number;
  disabled?: boolean;
};

type GridSelectorProps = {
  options: GridSelectorOption[];
  valueAttribute?: string;
  onClick: (x: string | number) => void;
  activeValueComparison?: string;
  disabled?: boolean;
};

export const GridSelector: Component<GridSelectorProps> = (props) => {
  return (
    <div class="grid grid-cols-3 gap-2 pt-2">
      <For each={props.options}>
        {(item) => (
          <button
            class="text-sm text-roma-dark-grey transition-colors px-2 py-2 border border-roma-dark-grey rounded-sm disabled:!text-roma-medium-grey disabled:!border-roma-medium-grey disabled:cursor-not-allowed"
            onClick={() => props.onClick(item.value)}
            classList={{
              "!text-white bg-black":
                props.activeValueComparison === item.value.toString(),
              // "!text-roma-blue hover:text-roma-blue !border-roma-blue ":
              //   props.activeValueComparison === item.value.toString(),
              // "hover:text-black": props.activeValueComparison !== item.value.toString(),
              "pointer-events-none": !!props.disabled,
            }}
            disabled={item.disabled || props.disabled}
          >
            <span>
              {item.width}
              <span class="text-xs mx-1">&#10005;</span>
              {item.height}
            </span>
          </button>
        )}
      </For>
    </div>
  );
};
